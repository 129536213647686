import React, { useEffect, useRef, useState } from "react";
import { storePartnersPageResopnseInDatabase } from "../../../redux/api";
import { isPhoneNumberValid } from "../../../utils/regexValidations";
import { formatPhoneNumber } from "../../../utils/phoneNumbers";
import { MdSend } from "react-icons/md";

const PhoneNumber = ({
  layoutType,
  formData,
  setFormData,
  handleChange,
  handleSubmit,
  handleNextStep,
  setIsNextStepButtonDisabled,
  company,
}) => {
  const inputRef = useRef(null);
  const [phoneNo, setPhoneNo] = useState(
    formData?.phoneNo ? formData?.phoneNo : ""
  );
  useEffect(() => {
    if (formData?.phoneNo && isPhoneNumberValid(formData?.phoneNo))
      setIsNextStepButtonDisabled(false);
    else setIsNextStepButtonDisabled(true);
  }, [formData?.phoneNo, setIsNextStepButtonDisabled]);

  // useEffect(() => {
  //   if (layoutType === "chat" && inputRef.current) {
  //     inputRef.current.focus(); // Auto-focus input in chat layout
  //   }
  // }, [layoutType]);

  useEffect(() => {
    if (layoutType === "chat") {
      if (formData.phoneNo && isPhoneNumberValid(formData.phoneNo)) {
        handleNextStep();
      }
    }
  }, [formData]);

  const handleInputChange = (e) => {
    setPhoneNo(e.target.value);
  };

  const handlePhoneSubmit = () => {
    if (phoneNo.trim() && isPhoneNumberValid(phoneNo)) {
      setFormData({ ...formData, phoneNo });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (formData?.phoneNo && isPhoneNumberValid(formData?.phoneNo)) {
      setIsNextStepButtonDisabled(false);
    } else {
      setIsNextStepButtonDisabled(true);
    }
  }, [formData?.phoneNo, setIsNextStepButtonDisabled]);

  if (layoutType === "chat") {
    return (
      <div className="items-center gap-3 w-full">
        <div className="relative flex-1">
          <input
            ref={inputRef}
            id="phone"
            name="phoneNo"
            type="tel"
            maxLength={14}
            value={formatPhoneNumber(phoneNo)}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Enter your phone number"
            className="w-full px-4 py-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-full shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
          />
        </div>
        <p className="text-xs text-gray-600 my-2">
          By clicking See Project Costs I am providing my ESIGN signature and
          express written consent agreement to permit {company?.name} or parties
          calling on their behalf to contact me on the number provided below for
          marketing purposes including the use of automated technology , SMS/MMS
          messages, AI generative voices, and prerecorded and/or artificial
          voice messages. I acknowledge my consent is not required to obtain any
          goods or service and I can see the projects{" "}
          <button
            onClick={() => handleNextStep(false)}
            className="text-blue-500 underline"
          >
            here
          </button>
          .
        </p>
        <button
          onClick={handlePhoneSubmit}
          disabled={!phoneNo.trim() || !isPhoneNumberValid(phoneNo)}
          className={`flex items-center justify-center p-2 rounded-full w-full ${
            !phoneNo.trim() || !isPhoneNumberValid(phoneNo)
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
        >
          See Project Costs
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="h-full w-full flex items-start justify-center flex-col gap-y-3">
        <p className="text-md text-gray-700">
          By clicking Agree & See Projects I am providing my ESIGN signature and
          express written consent agreement to permit {company?.name} or parties
          calling on their behalf to contact me on the number provided below for
          marketing purposes including the use of automated technology , SMS/MMS
          messages, AI generative voices, and prerecorded and/or artificial
          voice messages. I acknowledge my consent is not required to obtain any
          goods or service and I can see the projects{" "}
          <button
            onClick={() => handleNextStep(false)}
            className="text-blue-500 underline"
          >
            here
          </button>
          .
        </p>
        <input
          id="phone"
          name="phoneNo"
          type="tel"
          maxLength={14}
          value={formatPhoneNumber(formData.phoneNo)}
          onChange={handleChange}
          placeholder="Enter your phone number"
          className="w-full p-2 bg-slate-100 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
        />
      </div>
    </>
  );
};

export default PhoneNumber;
