import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { MdOutlineCancel } from "react-icons/md";
import profileImage from "../../../assets/images/conversationBot.png";
import ActiveStep from "./ActiveStep";
import ShowSystemRange from "./ShowSystemRange";

const ConversationLayout = ({
  open,
  setOpen,
  steps,
  setSteps,
  activeStep,
  setActiveStep,
  appearanceSettings,
  handleNextStep,
  handlePrevStep,
  formData,
  company,
  landingPageDetails,
  setFormData,
  handleChange,
  isNextDisabled,
  setIsNextDisabled,
  messages,
  setMessages,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showProjectSelectedOption, setShowProjectSelectedOption] =
    useState(null);
  const [currentTypingMessage, setCurrentTypingMessage] = useState("");
  const [conversationStarted, setConversationStarted] = useState(false);
  const [stepOptions, setStepOptions] = useState([]);
  const messagesEndRef = useRef(null);
  const layoutType = "chat";

  const initialMessage =
    "Hi! 👋 I'm Chloe, would you like me to help you calculate your required system size and show you other work we have done in your area?";
  const noThanksMessage = "No problem. I am here if you change your mind.";

  useEffect(() => {
    if (open) {
      setMessages([]);
      setSelectedOption(null);
      setConversationStarted(true);
      typeMessage(steps[activeStep - 1]?.conversationMessage);
    }
  }, [open]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, currentTypingMessage]);

  useEffect(() => {
    if (formData[steps[activeStep - 1]?.key]) {
      if (activeStep !== 7) {
        if (steps[activeStep - 1]?.key === "firstName") {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              text: `${formData[steps[activeStep - 1]?.key]} ${
                formData?.lastName
              }`,
              type: "user",
            },
          ]);
        } else {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: formData[steps[activeStep - 1]?.key], type: "user" },
          ]);
        }
      }
      if (activeStep === 7) {
        typeMessage(
          `We have completed ${formData?.numberOfRelevantProjects} similar projects.`
        );
      }
    }
  }, [formData]);

  useEffect(() => {
    if (activeStep >= 1 && activeStep !== 7) {
      if (steps[activeStep - 1]?.conversationMessage) {
        typeMessage(steps[activeStep - 1]?.conversationMessage);
      }
    }
    console.log("Active Step: ", activeStep);
  }, [activeStep]);

  const typeMessage = (message, callback) => {
    setCurrentTypingMessage("");

    let index = 0;
    const interval = setInterval(() => {
      if (index < message.length) {
        setCurrentTypingMessage((prev) => prev + message[index]);
        index++;
      } else {
        clearInterval(interval);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: message, type: "bot" },
        ]);
        setCurrentTypingMessage(null);
        if (callback) callback();
      }
    }, 30);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Dialog open={open} onClose={() => {}} className="relative z-50">
      <Dialog.Backdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
        <Dialog.Panel className="relative w-full max-w-md h-[500px] sm:h-[600px] transform overflow-hidden rounded-lg bg-white shadow-xl transition-all flex flex-col">
          {/* Header */}
          <div className="p-4 flex justify-between items-center border-b">
            <Dialog.Title className="text-lg font-semibold text-gray-900">
              Chat with Chloe
            </Dialog.Title>
            <MdOutlineCancel
              className="text-gray-400 hover:text-gray-500 cursor-pointer w-6 h-6"
              onClick={() => setOpen(false)}
            />
          </div>

          {/* Chat Messages */}
          <div
            className="p-4 space-y-3 overflow-y-auto flex-1"
            style={{ scrollbarWidth: "none" }}
          >
            {/* Render All Messages */}
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`flex ${
                  msg.type === "bot" ? "items-start gap-3" : "justify-end"
                }`}
              >
                {msg.type === "bot" && (
                  <img
                    src={profileImage}
                    alt="Chloe"
                    className="w-10 h-10 rounded-full"
                  />
                )}
                <div
                  className={`p-3 rounded-lg max-w-xs ${
                    msg.type === "bot"
                      ? "bg-gray-100 w-3/4"
                      : "bg-blue-100 w-fit self-end"
                  }`}
                >
                  {msg?.component ? msg.component : <p>{msg.text}</p>}
                </div>
              </div>
            ))}

            {/* Typing Effect for Current Message */}
            {currentTypingMessage && (
              <div className="flex items-start gap-3">
                <img
                  src={profileImage}
                  alt="Chloe"
                  className="w-10 h-10 rounded-full"
                />
                <div className="bg-gray-100 p-3 rounded-lg w-3/4">
                  <p>{currentTypingMessage}</p>
                </div>
              </div>
            )}

            {/* Initial Options (Yes/No) */}
            {/* {!selectedOption &&
              !currentTypingMessage &&
              !conversationStarted && (
                <div className="flex justify-end gap-3">
                  <button
                    onClick={() => handleOptionClick("Yes please")}
                    className="px-4 py-2 rounded-full bg-blue-600 text-white text-sm font-medium hover:bg-blue-700"
                  >
                    Yes please
                  </button>
                  <button
                    onClick={() => handleOptionClick("No thanks")}
                    className="px-4 py-2 rounded-full bg-gray-300 text-gray-700 text-sm font-medium hover:bg-gray-400"
                  >
                    No thanks
                  </button>
                </div>
              )} */}

            {/* ActiveStep Component inside Chat (Only after conversation starts) */}
            {conversationStarted && !currentTypingMessage && (
              <ActiveStep
                steps={steps}
                setSteps={setSteps}
                activeStep={activeStep}
                formData={formData}
                setFormData={setFormData}
                appearanceSettings={appearanceSettings}
                company={company}
                landingPageDetails={landingPageDetails}
                handleChange={handleChange}
                handleNextStep={handleNextStep}
                setIsNextDisabled={setIsNextDisabled}
                setIsNextStepButtonDisabled={setIsNextDisabled}
                layoutType="chat"
                setStepOptions={setStepOptions} // Pass options
                messages={messages}
                setMessages={setMessages}
              />
            )}
            {/* {activeStep === 6 && !showProjectSelectedOption && !currentTypingMessage && (
              <div className="flex justify-end gap-3">
                <button
                  onClick={() => handleOptionClick("Yes please")}
                  className="px-4 py-2 rounded-full bg-blue-600 text-white text-sm font-medium hover:bg-blue-700"
                >
                  Yes please
                </button>
              </div>
            )} */}

            {/* Step Options as Chat Buttons */}
            {/* {conversationStarted && stepOptions.length > 0 && (
              <div className="flex justify-end gap-3">
                {stepOptions.map((option, index) => (
                  <button
                    key={index}
                    onClick={() => handleOptionClick(option)}
                    className="px-4 py-2 rounded-full bg-blue-600 text-white text-sm font-medium hover:bg-blue-700"
                  >
                    {option}
                  </button>
                ))}
              </div>
            )} */}

            <div ref={messagesEndRef} />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default ConversationLayout;
