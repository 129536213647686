import React, { useEffect, useState, useRef } from "react";
import { isEmailValid } from "../../../utils/regexValidations";
import { MdSend } from "react-icons/md";

const Email = ({
  layoutType,
  formData,
  handleChange,
  setFormData,
  setIsNextStepButtonDisabled,
  handleNextStep,
}) => {
  const inputRef = useRef(null);
  const [email, setEmail] = useState(formData?.email ? formData?.email : "");
  useEffect(() => {
    if (formData?.email && isEmailValid(formData?.email))
      setIsNextStepButtonDisabled(false);
    else setIsNextStepButtonDisabled(true);
  }, [formData?.email, setIsNextStepButtonDisabled]);

  // useEffect(() => {
  //   if (layoutType === "chat" && inputRef.current) {
  //     inputRef.current.focus(); // Auto-focus input in chat layout
  //   }
  // }, [layoutType]);

  useEffect(() => {
    if (layoutType === "chat") {
      if (formData.email && isEmailValid(formData.email)) {
        handleNextStep();
      }
    }
  }, [formData]);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (email.trim() && isEmailValid(email)) {
      setFormData({ ...formData, email });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  if (layoutType === "chat") {
    return (
      <div className="flex items-center gap-3 w-full">
        <div className="relative flex-1">
          <input
            ref={inputRef}
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Enter your email"
            className="w-full px-4 py-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-full shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
          />
          <button
            onClick={handleSubmit}
            disabled={!email.trim() || !isEmailValid(email)}
            className={`absolute inset-y-1 right-2 flex items-center justify-center p-2 rounded-full ${
              email.trim() && isEmailValid(email)
                ? "bg-blue-600 hover:bg-blue-700 text-white"
                : "bg-gray-400 text-gray-200 cursor-not-allowed"
            }`}
          >
            <MdSend className="w-4 h-4" />
          </button>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="h-full w-full flex justify-start items-center">
        <input
          id="email"
          name="email"
          type="email"
          value={formData?.email}
          onChange={handleChange}
          placeholder="Enter your email"
          className="w-full p-2 bg-slate-100 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
        />
      </div>
    </>
  );
};

export default Email;
