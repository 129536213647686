import React, { useEffect, useRef, useState } from "react";
import { MdSend } from "react-icons/md"; // Send icon for button

const VerifyHuman = ({
  layoutType,
  formData,
  handleChange,
  setFormData,
  setIsNextStepButtonDisabled,
  handleNextStep,
}) => {
  const inputRef = useRef(null);
  const [fullName, setFullName] = useState(
    formData?.firstName && formData?.lastName
      ? `${formData.firstName} ${formData.lastName}`
      : ""
  );

  useEffect(() => {
    setIsNextStepButtonDisabled(!(formData?.firstName && formData.lastName));
  }, [formData?.firstName, formData?.lastName, setIsNextStepButtonDisabled]);

  useEffect(() => {
    if (layoutType === "chat") {
      setIsNextStepButtonDisabled(!fullName.trim());
    }
  }, [fullName, setIsNextStepButtonDisabled]);

  // useEffect(() => {
  //   if (layoutType === "chat" && inputRef.current) {
  //     inputRef.current.focus(); // Auto-focus input in chat layout
  //   }
  // }, [layoutType]);

  useEffect(() => {
    if (layoutType === "chat") {
      if (formData.firstName && formData.lastName) {
        handleNextStep();
      }
    }
  }, [formData]);

  // Handle input change
  const handleInputChange = (e) => {
    setFullName(e.target.value);
  };

  // Handle submission (button click or Enter key)
  const handleSubmit = () => {
    if (fullName.trim()) {
      const [firstName, ...lastNameParts] = fullName.trim().split(" ");
      const lastName = lastNameParts.join(" ") || ""; // Handle cases where only first name is entered
      setFormData({ ...formData, firstName, lastName });
    }
  };

  // Handle Enter key press in chat layout
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  if (layoutType === "chat") {
    return (
      <div className="flex items-center gap-3 w-full">
        <div className="relative flex-1">
          <input
            ref={inputRef}
            id="fullName"
            name="fullName"
            type="text"
            value={fullName}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Enter your full name"
            className="w-full px-4 py-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-full shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
          />
          <button
            onClick={handleSubmit}
            disabled={!fullName.trim()}
            className={`absolute inset-y-1 right-2 flex items-center justify-center p-2 rounded-full ${
              fullName.trim()
                ? "bg-blue-600 hover:bg-blue-700 text-white"
                : "bg-gray-400 text-gray-200 cursor-not-allowed"
            }`}
          >
            <MdSend className="w-4 h-4" />
          </button>
        </div>
      </div>
    );
  }

  // Default Form Layout
  return (
    <div className="h-full w-full flex items-center justify-start">
      <div className="w-full">
        <form>
          <div className="grid md:grid-cols-2 gap-4">
            {/* First Name */}
            <input
              id="firstName"
              name="firstName"
              type="text"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full p-2 bg-slate-100 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
            />

            {/* Last Name */}
            <input
              id="lastName"
              name="lastName"
              type="text"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full p-2 bg-slate-100 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyHuman;
