import React, { useEffect } from "react";

const Levels = ({ formData, handleChange, setIsNextStepButtonDisabled, layoutType }) => {
  const levels = ["1", "2", "3+"];
  useEffect(() => {
    setIsNextStepButtonDisabled(!formData?.level);
  }, [formData?.level]);

  if (layoutType === "chat") {
    return (
      <div className="flex justify-end gap-3">
        {levels?.map((level, index) => (
          <label key={index}>
            <input
              type="radio"
              name="level"
              value={level}
              onChange={handleChange}
              checked={formData?.level === level}
              className="hidden" // Hide the actual radio button
            />
            <span
              className={`px-4 py-2 rounded-full text-sm font-medium cursor-pointer transition 
                ${
                  formData?.level === level
                    ? "bg-blue-700 text-white" // Selected level
                    : "bg-blue-600 text-white hover:bg-blue-700"
                }`}
            >
              {level}
            </span>
          </label>
        ))}
      </div>
    );
  }

  return (
    <>
      <div
        className="grid grid-cols-1 gap-3"
        role="group"
        aria-labelledby="my-radio-group"
      >
        {levels?.map((level, index) => (
          <div className="bg-slate-100 rounded-lg p-3" key={index}>
            <label className="block text-sm text-gray-700">
              <input
                className="appearance-none w-4 h-4 border border-gray-400 rounded-full checked:bg-blue-500 checked:border-transparent focus:outline-none"
                type="radio"
                name="level"
                value={level}
                checked={formData?.level === level}
                onChange={handleChange}
              />
              <span className="ms-2">{level}</span>
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default Levels;
