import React, { useEffect } from "react";

const PropertyType = ({
  layoutType,
  formData,
  handleChange,
  setIsNextStepButtonDisabled,
}) => {
  const propertyTypes = ["Residential", "Commercial"];

  useEffect(() => {
    setIsNextStepButtonDisabled(!formData?.propertyType);
  }, [formData?.propertyType]);

  // 🟢 Chat Layout (Buttons Styled as Pills)
  if (layoutType === "chat") {
    return (
      <div className="flex justify-end gap-3">
        {propertyTypes.map((propertyType, index) => (
          <label key={index}>
            <input
              type="radio"
              name="propertyType"
              value={propertyType}
              onChange={handleChange}
              checked={formData?.propertyType === propertyType}
              className="hidden" // Hide the actual radio button
            />
            <span
              className={`px-4 py-2 rounded-full text-sm font-medium cursor-pointer transition 
                ${
                  formData?.propertyType === propertyType
                    ? "bg-blue-700 text-white" // Selected option
                    : "bg-blue-600 text-white hover:bg-blue-700"
                }`}
            >
              {propertyType}
            </span>
          </label>
        ))}
      </div>
    );
  }

  // 🟢 Form-Based UI (for Modal)
  return (
    <div
      className="grid grid-cols-1 gap-3"
      role="group"
      aria-labelledby="my-radio-group"
    >
      {propertyTypes.map((propertyType, index) => (
        <div className="bg-slate-100 rounded-lg p-3" key={index}>
          <label className="block text-sm text-gray-700">
            <input
              className="appearance-none w-4 h-4 border border-gray-400 rounded-full checked:bg-blue-500 checked:border-transparent focus:outline-none"
              type="radio"
              name="propertyType"
              value={propertyType}
              checked={formData?.propertyType === propertyType}
              onChange={handleChange}
            />
            <span className="ms-2">{propertyType}</span>
          </label>
        </div>
      ))}
    </div>
  );
};

export default PropertyType;
