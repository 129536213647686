import React, { useEffect, useState } from "react";
import { GoogleMap, MarkerClustererF, MarkerF } from "@react-google-maps/api";
import { Oval } from "react-loader-spinner";

const RoofingProjectsSummary = ({
  formData,
  appearanceSettings,
  landingPageDetails,
  layoutType,
  handleNextStep,
  setIsNextStepButtonDisabled,
  messages,
  setMessages,
}) => {
  const options = {
    disableDefaultUI: true,
    // minZoom: 10,
    maxZoom: 14,
    mapId: "a216e88580f77d70",
    restriction: {
      latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
      strictBounds: true,
    },
    draggable: false, // Disable map dragging
    scrollwheel: false, // Disable zooming with scroll wheel
    disableDoubleClickZoom: true, // Disable zooming on double-click
  };

  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (map) {
      map.setOptions({
        zoomControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        rotateControl: false,
        panControl: false,
        draggable: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
      });
    }
  }, [map]);

  useEffect(() => {
    if (
      formData?.numberOfRelevantProjects &&
      formData?.projectsCosts &&
      formData?.coordinates &&
      formData?.projects
    ) {
      setLoading(false);
    }
  }, [formData]);

  useEffect(() => {
    setIsNextStepButtonDisabled(false);
  }, [loading]);

  function generateClusterIcon(color, text) {
    return `
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.74 88.08">
        <defs>
          <style>
            .cls-1, .cls-2, .cls-3, .cls-4 { fill: ${color}; }
            .cls-1 { opacity: 0.8; }
            .cls-2 { opacity: 0.6; }
            .cls-3 { opacity: 0.4; }
          </style>
        </defs>
        <path class="cls-1" d="M42.36,82v3.07A36.95,36.95,0,0,1,15,60.44h0a36.81,36.81,0,0,1,0-22.74l2.66,1.53A33.84,33.84,0,0,0,42.36,82Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-1" d="M86.79,49.06a36.86,36.86,0,0,1-29.15,36V82A33.81,33.81,0,0,0,82.33,39.22L85,37.69A36.58,36.58,0,0,1,86.79,49.06Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-1" d="M77.33,24.46,74.67,26a33.73,33.73,0,0,0-49.34,0l-2.66-1.53a36.72,36.72,0,0,1,54.66,0Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-2" d="M42.36,86V89A40.73,40.73,0,0,1,11.58,62.42a40.62,40.62,0,0,1,0-26.72l2.64,1.53A37.74,37.74,0,0,0,42.36,86Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-2" d="M90.68,49.06a40.63,40.63,0,0,1-9.91,26.59A40.67,40.67,0,0,1,57.64,89V86A37.7,37.7,0,0,0,85.78,37.23l2.64-1.53A40.21,40.21,0,0,1,90.68,49.06Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-2" d="M80.77,22.47,78.11,24a37.61,37.61,0,0,0-56.22,0l-2.66-1.53A40.67,40.67,0,0,1,42.36,9.11a40.34,40.34,0,0,1,15.28,0A40.67,40.67,0,0,1,80.77,22.47Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-3" d="M42.36,89.72v3a44.54,44.54,0,0,1-34-28.47h0a44.3,44.3,0,0,1,0-30.46L11,35.35A41.41,41.41,0,0,0,42.36,89.72Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-3" d="M94.37,49.06a44,44,0,0,1-2.7,15.23h0a44.54,44.54,0,0,1-34,28.47v-3A41.37,41.37,0,0,0,89,35.35l2.64-1.52A44,44,0,0,1,94.37,49.06Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-3" d="M84,20.6l-2.64,1.52A41.34,41.34,0,0,0,57.64,8.4a41.46,41.46,0,0,0-15.28,0A41.34,41.34,0,0,0,18.63,22.12L16,20.6a44.3,44.3,0,0,1,68,0Z" transform="translate(-5.63 -4.69)"/>
        <path class="cls-4" d="M82,49.06A32,32,0,0,1,57.64,80.15a32,32,0,0,1-15.28,0A32,32,0,0,1,19.19,58h0A32,32,0,0,1,42.36,17.94a32,32,0,0,1,15.28,0A32,32,0,0,1,82,49.06Z" transform="translate(-5.63 -4.69)"/>
      </svg>
    `;
  }

  function getClusterStyles(userColor) {
    return [
      {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
          generateClusterIcon(userColor, 1)
        )}`,
        height: 50,
        width: 50,
        textColor: "#FFFFFF", // Text color inside the SVG
        textSize: 14,
        anchorText: [0, 0],
      },
      {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
          generateClusterIcon(userColor, 2)
        )}`,
        height: 60,
        width: 60,
        textColor: "#FFFFFF",
        textSize: 14,
        anchorText: [0, 0],
      },
      {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
          generateClusterIcon(userColor, 3)
        )}`,
        height: 70,
        width: 70,
        textColor: "#FFFFFF",
        textSize: 16,
        anchorText: [0, 0],
      },
    ];
  }

  const getWidthPercentage = (cost) => {
    return (cost / formData?.projectsCosts?.max) * 100 + "%";
  };
  const formatNumber = (number) => {
    return Math.trunc(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  if (layoutType === "chat") {
    return (
      <div className="h-full">
        {loading ? (
          <>
            <div className="flex justify-center items-center h-full gap-x-2">
              <Oval
                height={28}
                width={28}
                color={appearanceSettings?.primaryColour}
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor={appearanceSettings?.secondaryColour}
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
              <p className="font-md text-gray-600">Finding Projects...</p>
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-3 justify-center">
              {/* <div className="col-span-2 flex flex-col">
                <div className="bg-orange-100 p-2 rounded-lg mb-2 flex-initial">
                  <p className="text-sm font-normal text-gray-600">
                    Similar Projects Completed
                  </p>
                  <p className="font-medium text-lg">
                    {formData?.numberOfRelevantProjects}
                  </p>
                </div>
                <div className="bg-sky-300 p-2 rounded-lg mb-2 flex-1">
                  <p className="text-sm font-normal text-gray-600">
                    Project Costs (after tax credits)
                  </p>
                  <div className="space-y-2 mt-2">
                    <div className="flex items-center justify-between">
                      <div className="text-left w-6">High</div>
                      <div className="flex-grow mx-4 flex">
                        <div
                          className="bg-blue-600 h-2.5 rounded-full"
                          style={{
                            width: getWidthPercentage(
                              formData?.projectsCosts?.max
                            ),
                          }}
                        ></div>
                      </div>
                      <div className="text-right text-sm">
                        {`$ ${formatNumber(formData?.projectsCosts?.max)}`}
                        <br />
                        <span className="text-xs text-gray-700">
                          {`$ ${formatNumber(
                            formData?.projectsCosts?.maxMonthlyPayment
                          )}/m`}
                        </span>
                      </div>
                    </div>
                    <hr className="border-gray-400 border-[0.5px]" />

                    <div className="flex items-center justify-between">
                      <div className="text-left w-6">Med</div>
                      <div className="flex-grow mx-4 flex">
                        <div
                          className="bg-blue-600 h-2.5 rounded-full"
                          style={{
                            width: getWidthPercentage(
                              formData?.projectsCosts?.mid
                            ),
                          }}
                        ></div>
                      </div>
                      <div className="text-right text-sm">
                        {`$ ${formatNumber(formData?.projectsCosts?.mid)}`}
                        <br />
                        <span className="text-xs text-gray-700">
                          {`$ ${formatNumber(
                            formData?.projectsCosts?.midMonthlyPayment
                          )}/m`}
                        </span>
                      </div>
                    </div>
                    <hr className="border-gray-400 border-[0.5px]" />

                    <div className="flex items-center justify-between">
                      <div className="text-left w-6">Low</div>
                      <div className="flex-grow mx-4 flex">
                        <div
                          className="bg-blue-600 h-2.5 rounded-full"
                          style={{
                            width: getWidthPercentage(
                              formData?.projectsCosts?.min
                            ),
                          }}
                        ></div>
                      </div>
                      <div className="text-right text-sm">
                        {`$ ${formatNumber(formData?.projectsCosts?.min)}`}
                        <br />
                        <span className="text-xs text-gray-700">
                          {`$ ${formatNumber(
                            formData?.projectsCosts?.minMonthlyPayment
                          )}/m`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-span-3 h-48">
                <GoogleMap
                  mapContainerStyle={{ height: "100%", width: "100%" }}
                  options={options}
                  center={{
                    lat: formData?.coordinates?.lat,
                    lng: formData?.coordinates?.lng,
                  }}
                  zoom={8}
                  onLoad={(map) => {
                    let isMounted = true;

                    if (isMounted) {
                      setMap(map);
                    }

                    return () => {
                      isMounted = false;
                    };
                  }}
                >
                  <MarkerClustererF
                    options={{
                      styles: getClusterStyles(
                        appearanceSettings?.primaryColour
                      ), // Pass the hex color code
                      zoomOnClick: false,
                    }}
                  >
                    {(clusterer) =>
                      formData?.projects?.map((project, index) => {
                        const [lng, lat] = project?.coordinates;
                        const position = {
                          lat: parseFloat(lat),
                          lng: parseFloat(lng),
                        };
                        return (
                          <MarkerF
                            key={project._id || index}
                            position={position}
                            clusterer={clusterer}
                            title={project?.address}
                          />
                        );
                      })
                    }
                  </MarkerClustererF>
                </GoogleMap>
              </div>
            </div>
            <div className="mt-4">
              Would you like to see the prices for these projects?
            </div>
            <button
              onClick={() => {
                // Set messages with the map and system size
                setMessages([
                  ...messages,
                  {
                    component: (
                      <>
                        <div className="grid grid-cols-1 md:grid-cols-5 gap-3">
                          {/* <div className="col-span-2 flex flex-col">
                              <div className="bg-orange-100 p-2 rounded-lg mb-2 flex-initial">
                                <p className="text-sm font-normal text-gray-600">
                                  Similar Projects Completed
                                </p>
                                <p className="font-medium text-lg">
                                  {formData?.numberOfRelevantProjects}
                                </p>
                              </div>
                              <div className="bg-sky-300 p-2 rounded-lg mb-2 flex-1">
                                <p className="text-sm font-normal text-gray-600">
                                  Project Costs (after tax credits)
                                </p>
                                <div className="space-y-2 mt-2">
                                  
                                  <div className="flex items-center justify-between">
                                    <div className="text-left w-6">High</div>
                                    <div className="flex-grow mx-4 flex">
                                      <div
                                        className="bg-blue-600 h-2.5 rounded-full"
                                        style={{
                                          width: getWidthPercentage(
                                            formData?.projectsCosts?.max
                                          ),
                                        }}
                                      ></div>
                                    </div>
                                    <div className="text-right text-sm">
                                      {`$ ${formatNumber(
                                        formData?.projectsCosts?.max
                                      )}`}
                                      <br />
                                      <span className="text-xs text-gray-700">
                                        {`$ ${formatNumber(
                                          formData?.projectsCosts
                                            ?.maxMonthlyPayment
                                        )}/m`}
                                      </span>
                                    </div>
                                  </div>
                                  <hr className="border-gray-400 border-[0.5px]" />
  
                                  <div className="flex items-center justify-between">
                                    <div className="text-left w-6">Med</div>
                                    <div className="flex-grow mx-4 flex">
                                      <div
                                        className="bg-blue-600 h-2.5 rounded-full"
                                        style={{
                                          width: getWidthPercentage(
                                            formData?.projectsCosts?.mid
                                          ),
                                        }}
                                      ></div>
                                    </div>
                                    <div className="text-right text-sm">
                                      {`$ ${formatNumber(
                                        formData?.projectsCosts?.mid
                                      )}`}
                                      <br />
                                      <span className="text-xs text-gray-700">
                                        {`$ ${formatNumber(
                                          formData?.projectsCosts
                                            ?.midMonthlyPayment
                                        )}/m`}
                                      </span>
                                    </div>
                                  </div>
                                  <hr className="border-gray-400 border-[0.5px]" />
  
                                  <div className="flex items-center justify-between">
                                    <div className="text-left w-6">Low</div>
                                    <div className="flex-grow mx-4 flex">
                                      <div
                                        className="bg-blue-600 h-2.5 rounded-full"
                                        style={{
                                          width: getWidthPercentage(
                                            formData?.projectsCosts?.min
                                          ),
                                        }}
                                      ></div>
                                    </div>
                                    <div className="text-right text-sm">
                                      {`$ ${formatNumber(
                                        formData?.projectsCosts?.min
                                      )}`}
                                      <br />
                                      <span className="text-xs text-gray-700">
                                        {`$ ${formatNumber(
                                          formData?.projectsCosts
                                            ?.minMonthlyPayment
                                        )}/m`}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          <div className="col-span-3 h-48">
                            <GoogleMap
                              mapContainerStyle={{
                                height: "100%",
                                width: "100%",
                              }}
                              options={options}
                              center={{
                                lat: formData?.coordinates?.lat,
                                lng: formData?.coordinates?.lng,
                              }}
                              zoom={8}
                              onLoad={(map) => {
                                let isMounted = true;

                                if (isMounted) {
                                  setMap(map);
                                }

                                return () => {
                                  isMounted = false;
                                };
                              }}
                            >
                              <MarkerClustererF
                                options={{
                                  styles: getClusterStyles(
                                    appearanceSettings?.primaryColour
                                  ), // Pass the hex color code
                                  zoomOnClick: false,
                                }}
                              >
                                {(clusterer) =>
                                  formData?.projects?.map((project, index) => {
                                    const [lng, lat] = project.coordinates;
                                    const position = {
                                      lat: parseFloat(lat),
                                      lng: parseFloat(lng),
                                    };
                                    return (
                                      <MarkerF
                                        key={project._id || index}
                                        position={position}
                                        clusterer={clusterer}
                                        title={project?.address}
                                      />
                                    );
                                  })
                                }
                              </MarkerClustererF>
                            </GoogleMap>
                          </div>
                        </div>
                        <div className="mt-4">
                          Would you like to see the prices for these projects?
                        </div>
                      </>
                    ),
                    type: "bot",
                  },
                  {
                    text: "Yes please",
                    type: "user",
                  },
                ]);
                handleNextStep();
              }}
              className="float-right mt-2 px-4 py-2 rounded-full bg-blue-600 text-white text-sm font-medium hover:bg-blue-700"
            >
              Yes please
            </button>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="h-full">
      {loading ? (
        <>
          <div className="flex justify-center items-center h-full gap-x-2">
            <Oval
              height={28}
              width={28}
              color={appearanceSettings?.primaryColour}
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor={appearanceSettings?.secondaryColour}
              strokeWidth={4}
              strokeWidthSecondary={4}
            />
            <p className="font-md text-gray-600">Finding Projects...</p>
          </div>
        </>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-3">
            <div className="col-span-2 flex flex-col">
              <div className="bg-orange-100 p-2 rounded-lg mb-2 flex-initial">
                <p className="text-sm font-normal text-gray-600">
                  Similar Projects Completed
                </p>
                <p className="font-medium text-lg">
                  {formData?.numberOfRelevantProjects}
                </p>
              </div>
              <div className="bg-sky-300 p-2 rounded-lg mb-2 flex-1">
                <p className="text-sm font-normal text-gray-600">
                  Project Costs
                </p>
                <div className="space-y-2 mt-5">
                  {/* High */}
                  <div className="flex items-center justify-between">
                    <div className="text-left w-6">High</div>
                    <div className="flex-grow mx-4 flex">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{
                          width: getWidthPercentage(
                            formData?.projectsCosts?.max
                          ),
                        }}
                      ></div>
                    </div>
                    <div className="text-right">
                      $ {formatNumber(formData?.projectsCosts?.max)}
                    </div>
                  </div>

                  {/* Med */}
                  <div className="flex items-center justify-between">
                    <div className="text-left w-6">Med</div>
                    <div className="flex-grow mx-4 flex">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{
                          width: getWidthPercentage(
                            formData?.projectsCosts?.mid
                          ),
                        }}
                      ></div>
                    </div>
                    <div className="text-right">
                      $ {formatNumber(formData?.projectsCosts?.mid)}
                    </div>
                  </div>

                  {/* Low */}
                  <div className="flex items-center justify-between">
                    <div className="text-left w-6">Low</div>
                    <div className="flex-grow mx-4 flex">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{
                          width: getWidthPercentage(
                            formData?.projectsCosts?.min
                          ),
                        }}
                      ></div>
                    </div>
                    <div className="text-right">
                      $ {formatNumber(formData?.projectsCosts?.min)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3 h-48 md:h-full">
              <GoogleMap
                mapContainerStyle={{ height: "100%", width: "100%" }}
                options={options}
                center={{
                  lat: formData?.coordinates?.lat,
                  lng: formData?.coordinates?.lng,
                }}
                zoom={8}
                onLoad={(map) => setMap(map)}
              >
                <MarkerClustererF
                  options={{
                    styles: getClusterStyles(appearanceSettings?.primaryColour), // Pass the hex color code
                    zoomOnClick: false,
                  }}
                >
                  {(clusterer) =>
                    formData?.projects?.map((project, index) => {
                      const [lng, lat] = project.coordinates;
                      const position = {
                        lat: parseFloat(lat),
                        lng: parseFloat(lng),
                      };
                      return (
                        <MarkerF
                          key={project._id || index}
                          position={position}
                          clusterer={clusterer}
                          title={project?.address}
                        />
                      );
                    })
                  }
                </MarkerClustererF>
              </GoogleMap>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RoofingProjectsSummary;
