import { Autocomplete } from "@react-google-maps/api";
import React, { useState, useEffect, useRef } from "react";
import {
  getSolarSystemSizeAndImage,
  storePartnersPageResopnseInDatabase,
} from "../../../redux/api";
import { Oval } from "react-loader-spinner";

const CalculateSystemSize = ({
  layoutType,
  formData,
  company,
  landingPageDetails,
  appearanceSettings,
  setFormData,
  handleNextStep,
  setIsNextStepButtonDisabled,
}) => {
  const [autoComplete, setAutoComplete] = useState(null);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [findingLocation, setFindingLocation] = useState(false);
  const [inputValue, setInputValue] = useState(formData?.address || ""); // Stores user input
  const inputRef = useRef(null);

  const systemSizeRange = (val) => {
    const match = val.match(/([\d.]+)kW\s*-\s*([\d.]+)kW/);
    return match
      ? { min: parseFloat(match[1]), max: parseFloat(match[2]) }
      : {};
  };

  // useEffect(() => {
  //   if (layoutType === "chat" && inputRef.current) {
  //     inputRef.current.focus(); // Auto-focus input in chat layout
  //   }
  // }, [layoutType]);

  useEffect(() => {
    setIsNextStepButtonDisabled(!formData?.address && !isAddressSelected);
  }, [formData?.address, isAddressSelected, setIsNextStepButtonDisabled]);

  const onLoadAutoComplete = (autoComplete) => {
    setAutoComplete(autoComplete);
  };

  const onPlaceChanged = () => {
    setFindingLocation(true);

    if (autoComplete !== null) {
      const p = autoComplete.getPlace();
      if (!p?.geometry?.location) {
        console.error("Place object does not contain geometry or location.");
        setFindingLocation(false);
        return;
      }

      let hasStreetNumber = false;
      let zipCode = "";
      if (p.address_components) {
        for (const component of p.address_components) {
          if (component.types.includes("street_number")) {
            hasStreetNumber = true;
          }
          if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          }
        }
      }

      if (!hasStreetNumber) {
        alert(
          "Please select a complete address that includes a street number."
        );
        setIsAddressSelected(false);
        setFindingLocation(false);
        return;
      }

      const latlng = {
        lat: p.geometry.location.lat(),
        lng: p.geometry.location.lng(),
      };

      getSolarSystemSizeAndImage(
        latlng.lat,
        latlng.lng,
        formData?.avgElectricBill
      )
        .then(async (res) => {
          console.log("Solar System Size and Image: ", res);
          setInputValue(p.formatted_address);
          setIsAddressSelected(true);
          setFindingLocation(false);
          if (layoutType === "chat") {
            const result = await storePartnersPageResopnseInDatabase(
              company?._id,
              {
                firstName: formData?.firstName,
                lastName: formData?.lastName,
                customerEmail: formData?.email,
                phoneNo: formData?.phoneNo,
                address: p.formatted_address,
                partnerId: company?._id,
                landingPageId: landingPageDetails?._id,
                serviceId: landingPageDetails?.service,
                trustedFormUrl: document.querySelector(
                  "input[name='xxTrustedFormCertUrl']"
                )?.value,
                url: window.location.href,
                queryDetails: {
                  roofType: formData?.roofType,
                  level: formData?.level,
                  propertyType: formData?.propertyType,
                  estimatedRoofArea: formData?.estimatedRoofArea,
                  avgElectricBill: formData?.avgElectricBill,
                  systemSizeMinRange: systemSizeRange(formData?.systemSizeRange)
                    ?.min,
                  systemSizeMaxRange: systemSizeRange(formData?.systemSizeRange)
                    ?.max,
                },
              }
            );

            setFormData((prevFormData) => ({
              ...prevFormData,
              partnerResponseId: result?.data?._id,
              address: p.formatted_address,
              coordinates: latlng,
              addressZipCode: zipCode,
              estimatedRoofArea:
                parseInt(
                  res?.data?.buildingInsights?.solarPotential?.wholeRoofStats
                    ?.areaMeters2
                ) * 10.764,
              systemSizeRange: res?.data?.systemSizeRange,
              savingsData: res?.data?.savingsData,
            }));

            handleNextStep();
          } else {
            setFormData((prevFormData) => ({
              ...prevFormData,
              address: p.formatted_address,
              coordinates: latlng,
              addressZipCode: zipCode,
              estimatedRoofArea:
                parseInt(
                  res?.data?.buildingInsights?.solarPotential?.wholeRoofStats
                    ?.areaMeters2
                ) * 10.764,
              systemSizeRange: res?.data?.systemSizeRange,
              savingsData: res?.data?.savingsData,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
          setFindingLocation(false);
        });
    }
  };

  const handleManualInputChangeChat = (e) => {
    setInputValue(e.target.value);
    setIsAddressSelected(false); // Reset selection when manually typing
  };
  const handleManualInputChangeForm = (e) => {
    setInputValue(e.target.value);
    setIsAddressSelected(false); // Reset selection when manually typing
  };

  const handleSubmit = () => {
    if (inputValue.trim() !== "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        address: inputValue,
      }));
      setIsAddressSelected(true);
      handleNextStep(); // Move to next step
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  // 🟢 **Chat Layout (Manual Input & Autocomplete with Submit Button)**
  if (layoutType === "chat") {
    return (
      <div className="flex items-start gap-3">
        <div className="flex-1 relative">
          <Autocomplete
            onLoad={onLoadAutoComplete}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              ref={inputRef}
              type="text"
              name="address"
              id="search"
              className="w-full px-4 py-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-full shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
              placeholder="Enter Address"
              value={inputValue}
              onChange={handleManualInputChangeChat} // Allow manual input
              onKeyPress={handleKeyPress}
            />
          </Autocomplete>
        </div>

        {/* 🟢 "Finding location..." inside chat bubble */}
        {findingLocation && (
          <div className="flex items-center gap-2 text-gray-600">
            <Oval
              height={18}
              width={18}
              color={appearanceSettings?.primaryColour}
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor={appearanceSettings?.secondaryColour}
              strokeWidth={4}
            />
            <p>Finding location...</p>
          </div>
        )}
      </div>
    );
  }

  // 🟢 **Form-Based UI (for Modal)**
  return (
    <>
      <p className="text-sm font-normal text-gray-500">
        Please enter an address so we can measure your roof and calculate your
        estimated system size.
      </p>
      <div className="h-full w-full flex items-center justify-start">
        <div className="flex flex-col gap-y-1 w-full">
          <div className="w-full">
            <Autocomplete
              onLoad={onLoadAutoComplete}
              onPlaceChanged={onPlaceChanged}
            >
              <input
                type="text"
                name="text"
                id="search"
                className="w-full p-2 mt-1 bg-slate-100 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:ring-2 focus:ring-green-500 focus:border-green-500 focus:outline-none sm:text-sm"
                placeholder="Enter Address"
                value={inputValue}
                onChange={handleManualInputChangeForm}
              />
            </Autocomplete>
          </div>

          {findingLocation && (
            <div className="flex justify-center h-full">
              <div className="flex items-center gap-2">
                <Oval
                  height={18}
                  width={18}
                  color={appearanceSettings?.primaryColour}
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor={appearanceSettings?.secondaryColour}
                  strokeWidth={4}
                />
                <p>Finding location...</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CalculateSystemSize;
