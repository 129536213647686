import React, { useEffect, useState } from "react";
import { getRoofTypes } from "../../../redux/api";

const RoofType = ({ layoutType, formData, handleChange, setOptions, setIsNextStepButtonDisabled }) => {
  useEffect(() => {
    setIsNextStepButtonDisabled(!formData?.roofType);
  }, [formData?.roofType]);
  const [roofTypes, setRoofTypes] = useState([]);

  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is still mounted

    getRoofTypes()
      .then((res) => {
        if (isMounted) {
          setRoofTypes(res?.data?.options || []);
          if (layoutType === "chat" && setOptions) {
            setOptions(res?.data?.options || []); // Send options to ConversationLayout
          }
        }
      })
      .catch((err) => {
        if (isMounted) console.log(err);
      });

    return () => {
      isMounted = false; // Cleanup function to prevent state update on unmount
    };
  }, [layoutType, setOptions]);

  if (layoutType === "chat") {
    return (
      <div className="flex flex-wrap max-w-full justify-end gap-3">
        {roofTypes.map((option, index) => (
          <label key={index}>
            <input
              type="radio"
              name="roofType"
              value={option}
              onChange={handleChange}
              checked={formData?.roofType === option}
              className="hidden" // Hide the actual radio button
            />
            <span
              className={`px-4 py-2 rounded-full text-sm font-medium cursor-pointer transition 
                ${
                  formData?.roofType === option
                    ? "bg-blue-700 text-white" // Selected option
                    : "bg-blue-600 text-white hover:bg-blue-700"
                }`}
            >
              {option}
            </span>
          </label>
        ))}
      </div>
    );
  }

  // Render Form-Based UI (for Modal)
  return (
    <div className="grid grid-cols-2 gap-3">
      {roofTypes.map((roofType, index) => (
        <div key={index} className="bg-slate-100 rounded-lg p-3">
          <label className="block text-sm text-gray-700">
            <input
              type="radio"
              name="roofType"
              value={roofType}
              onChange={handleChange}
              checked={formData?.roofType === roofType}
              className="appearance-none w-4 h-4 border border-gray-400 rounded-full checked:bg-blue-500 checked:border-transparent focus:outline-none"
            />
            <span className="ms-2">{roofType}</span>
          </label>
        </div>
      ))}
    </div>
  );
};

export default RoofType;
