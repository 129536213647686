import React, { useEffect } from "react";
import { Oval } from "react-loader-spinner";

const ActiveStep = ({
  steps,
  setSteps,
  activeStep,
  formData,
  setFormData,
  appearanceSettings,
  landingPageDetails,
  handleChange,
  handleNextStep,
  setIsNextDisabled,
  setIsNextStepButtonDisabled,
  company,
  layoutType = "form",
  setStepOptions,
  messages,
  setMessages,
}) => {
  const StepComponent = steps?.[activeStep - 1]?.component;

  return (
    <>
      <StepComponent
        steps={steps}
        setSteps={setSteps}
        formData={formData}
        setFormData={setFormData}
        appearanceSettings={appearanceSettings}
        landingPageDetails={landingPageDetails}
        handleChange={handleChange}
        handleNextStep={handleNextStep}
        setIsNextDisabled={setIsNextDisabled}
        setIsNextStepButtonDisabled={setIsNextStepButtonDisabled}
        company={company}
        layoutType={layoutType}
        setOptions={setStepOptions}
        messages={messages}
        setMessages={setMessages}
      />
    </>
  );
};

export default ActiveStep;
