import React, { useEffect, useState } from "react";
import { GoogleMap } from "@react-google-maps/api";
import { Oval } from "react-loader-spinner";

const ShowRoofArea = ({
  layoutType,
  formData,
  appearanceSettings,
  handleNextStep,
  messages,
  setMessages,
}) => {
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (formData?.estimatedRoofArea) {
      setLoading(false);
    }
  }, [formData?.estimatedRoofArea]);

  const options = {
    disableDefaultUI: true,
    mapId: "a216e88580f77d70",
    mapTypeId: "satellite",
    draggable: false,
    zoomControl: false,
  };

  const formatNumber = (number) => {
    return Math.trunc(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  if (layoutType === "chat") {
    return (
      <>
        <div className="bg-slate-100 rounded-lg p-3 h-56 w-full">
          <GoogleMap
            mapContainerStyle={{ height: "100%", width: "100%" }}
            options={options}
            center={{
              lat: parseFloat(formData?.coordinates?.lat || 0),
              lng: parseFloat(formData?.coordinates?.lng || 0),
            }}
            zoom={19}
            onLoad={(map) => setMap(map)}
            mapTypeId="satellite"
          ></GoogleMap>
        </div>

        <h3 className="sm:text-lg text-md text-blue-500 font-medium text-center">
          {`${formatNumber(formData?.estimatedRoofArea)} Sqft`}
        </h3>

        <div className="flex justify-end gap-3">
          <button
            onClick={() => {
              // Set messages with the map and system size
              setMessages([
                ...messages,
                {
                  component: (
                    <div className="flex flex-col items-center gap-2">
                      <div className="bg-slate-100 rounded-lg p-3 h-56 w-full">
                        <GoogleMap
                          mapContainerStyle={{ height: "100%", width: "100%" }}
                          options={options}
                          center={{
                            lat: parseFloat(formData?.coordinates?.lat || 0),
                            lng: parseFloat(formData?.coordinates?.lng || 0),
                          }}
                          zoom={19}
                          onLoad={(map) => setMap(map)}
                          mapTypeId="satellite"
                        ></GoogleMap>
                      </div>
                      <h3 className="sm:text-lg text-md text-blue-500 font-medium">
                        {`${formatNumber(formData?.estimatedRoofArea)} Sqft`}
                      </h3>
                    </div>
                  ),
                  type: "bot",
                },
                {
                  text: "Yes please",
                  type: "user",
                },
              ]);
              handleNextStep();
            }}
            className="px-4 py-2 rounded-full bg-blue-600 text-white text-sm font-medium hover:bg-blue-700"
          >
            Yes please
          </button>
        </div>
      </>
    );
  }

  return (
    <div className="flex flex-col items-center gap-2">
      <div className="bg-slate-100 rounded-lg p-3 h-56 w-full">
        <GoogleMap
          mapContainerStyle={{ height: "100%", width: "100%" }}
          options={options}
          center={{
            lat: parseFloat(formData?.coordinates?.lat || 0),
            lng: parseFloat(formData?.coordinates?.lng || 0),
          }}
          zoom={19}
          onLoad={(map) => setMap(map)}
          mapTypeId="satellite"
        ></GoogleMap>
      </div>
      {loading ? (
        <>
          <div className="flex justify-center items-center h-full gap-x-2">
            <Oval
              height={22}
              width={22}
              color={appearanceSettings?.primaryColour}
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor={appearanceSettings?.secondaryColour}
              strokeWidth={4}
              strokeWidthSecondary={4}
            />
            <p className="font-md text-gray-600">Calculating Roof Area...</p>
          </div>
        </>
      ) : (
        <h3 className="sm:text-lg text-md text-blue-500 font-medium">
          {`${formatNumber(formData?.estimatedRoofArea)} Sqft`}
        </h3>
      )}
    </div>
  );
};

export default ShowRoofArea;
