import React, { useEffect, useRef, useState } from "react";
import { MdSend } from "react-icons/md"; // Send icon for button

const AverageElectricBill = ({
  layoutType,
  formData,
  setFormData,
  handleChange,
  handleNextStep,
  setIsNextStepButtonDisabled,
}) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(formData?.avgElectricBill || ""); // Temporary state

  useEffect(() => {
    setIsNextStepButtonDisabled(!formData?.avgElectricBill); // Disable next button if no value
  }, [formData?.avgElectricBill]);

  // useEffect(() => {
  //   if (layoutType === "chat" && inputRef.current) {
  //     inputRef.current.focus(); // Auto-focus input in chat layout
  //   }
  // }, [layoutType]);

  // Handle input change locally
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle submission (button click or Enter key)
  const handleSubmit = () => {
    if (inputValue.trim() !== "") {
      handleChange({ target: { name: "avgElectricBill", value: inputValue } }); // Call handleChange manually
      setFormData({ ...formData, avgElectricBill: inputValue });
      // handleNextStep(); // Move to next step
    }
  };

  // Handle Enter key press
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  if (layoutType === "chat") {
    return (
      <div className="flex items-center gap-3">
        <div className="flex-1 relative">
          <input
            ref={inputRef}
            id="avgElectricBill"
            name="avgElectricBill"
            type="number"
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Enter your average electric bill"
            className="w-full px-4 py-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-full shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
          />
          <button
            onClick={handleSubmit}
            className="absolute inset-y-1 right-2 flex items-center justify-center p-2 rounded-full bg-blue-600 hover:bg-blue-700 text-white"
          >
            <MdSend className="w-4 h-4" />
          </button>
        </div>
      </div>
    );
  }

  // 🟢 Form-Based UI (for Modal)
  return (
    <div className="h-full w-full flex justify-start items-center">
      <input
        id="avgElectricBill"
        name="avgElectricBill"
        type="number"
        value={formData?.avgElectricBill || ""}
        onChange={handleChange}
        placeholder="Enter your average electric bill"
        className="w-full p-2 bg-slate-100 border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none sm:text-sm"
      />
    </div>
  );
};

export default AverageElectricBill;
